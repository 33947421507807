import request from './request'

export default {
  async postTemplateEvent(machine_id, event) {
    console.log('postTemplateEvent(machine_id=', machine_id, '), event:', event)
    const result = await request.request(
      `/api/machines/${machine_id}/template/events/`,
      'post',
      event,
      {}
    )
    console.log('result: ', result)
    return result
  },

  async getTemplateEvents(machine_id) {
    let url = `/api/machines/${machine_id}/template/events/`
    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  },

  async patchTemplateEvent(event) {
    console.log('patchTemplateEvent: ', event)
    let id = event.id
    const result = await request.request(
      `/api/template/events/${id}/`,
      'patch',
      event,
      {}
    )
    console.log('result: ', result)
    return result
  },

  async deleteTemplateEvent(id) {
    console.log('deleteTemplateEvent(', id, ')')
    const result = await request.request(
      `/api/template/events/${id}/`,
      'delete',
      {},
      {}
    )
    console.log('result: ', result)
    return result
  },

  async postScheduleEvent(machine_id, event) {
    console.log('postScheduleEvent(machine_id=', machine_id, '), event:', event)
    const result = await request.request(
      `/api/machines/${machine_id}/schedule/events/`,
      'post',
      event,
      {}
    )
    console.log('result: ', result)
    return result
  },

  async getScheduleEvents(machine_id, from, to) {
    let url = `/api/machines/${machine_id}/schedule/events/?from=${from}&to=${to}`
    return request.allPages(url, url => {
      // console.log("url:", url)
      return request.request(url, 'get', {}, {})
    })
  },

  async patchScheduleEvent(event) {
    console.log('patchScheduleEvent:', event)
    let id = event.id
    const result = await request.request(
      `/api/schedule/events/${id}/`,
      'patch',
      event,
      {}
    )
    console.log('result: ', result)
    return result
  },

  async deleteScheduleEvent(id) {
    console.log('deleteScheduleEvent(', id, ')')
    const result = await request.request(
      `/api/schedule/events/${id}/`,
      'delete',
      {},
      {}
    )
    console.log('result: ', result)
    return result
  },

  async sync(machine_id, req) {
    console.log('sync events for machine:', machine_id, ', req:', req)

    // /api/machines/1/template/events/copy/

    let url = `/api/machines/${machine_id}/template/events/sync/`
    // console.log("url:", url)
    return await request.request(url, 'put', req, {})
  }
}
